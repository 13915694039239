<svg
   width="210mm"
   height="297mm"
   viewBox="0 0 210 297"
   version="1.1"
   id="svg5"
   inkscape:version="1.1.2 (b8e25be8, 2022-02-05)"
   sodipodi:docname="site-placeholder0.svg"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <sodipodi:namedview
     id="namedview7"
     pagecolor="#505050"
     bordercolor="#ffffff"
     borderopacity="1"
     inkscape:pageshadow="0"
     inkscape:pageopacity="0"
     inkscape:pagecheckerboard="1"
     inkscape:document-units="mm"
     showgrid="false"
     inkscape:zoom="0.64052329"
     inkscape:cx="161.58663"
     inkscape:cy="535.49966"
     inkscape:window-width="1728"
     inkscape:window-height="1080"
     inkscape:window-x="0"
     inkscape:window-y="37"
     inkscape:window-maximized="0"
     inkscape:current-layer="layer2" />
  <defs
     id="defs2" />
  <g
     inkscape:label="Layer 1"
     inkscape:groupmode="layer"
     id="layer1">
    <g
       inkscape:label="ufo"
       id="layer1-8"
       style="display:inline"
       transform="translate(6.1811855,13.084742)">
      <ellipse
         style="fill:#333333;stroke-width:0.239744"
         id="path143"
         cx="106.58794"
         cy="66.193504"
         rx="79.723228"
         ry="16.109875" />
      <ellipse
         style="fill:#333333;stroke-width:0.332342"
         id="path247"
         cx="107.41408"
         cy="56.899342"
         rx="57.004169"
         ry="14.664117" />
      <ellipse
         style="fill:#333333;stroke-width:0.264583"
         id="path351"
         cx="104.31603"
         cy="44.094051"
         rx="25.817108"
         ry="10.120306" />
      <ellipse
         style="fill:#333333;stroke-width:0.264583"
         id="path561"
         cx="105.14218"
         cy="78.585701"
         rx="37.383171"
         ry="10.739917" />
      <path
         style="display:inline;fill:#ffe680;stroke-width:0.344302"
         d="m 36.055717,244.9473 c 7.929755,-27.7882 45.25547,-157.152354 45.420581,-157.419512 0.160869,-0.260289 1.411618,-0.164962 4.161009,0.317132 6.776234,1.188186 10.901577,1.509374 19.424643,1.512354 5.68203,0.0021 9.82276,-0.18264 13.25566,-0.59104 2.74582,-0.32666 5.29285,-0.623067 5.66006,-0.65868 0.60091,-0.05828 2.95116,7.864975 23.50977,79.257306 12.56316,43.62714 22.79838,79.35732 22.74494,79.40042 -0.0534,0.0431 -30.41353,0.17201 -67.46686,0.28641 l -67.369692,0.20804 z"
         id="path2631" />
      <ellipse
         style="fill:#ffe680;stroke-width:0.513395"
         id="path115"
         cx="102.89739"
         cy="247.45511"
         rx="67.409981"
         ry="17.903755" />
    </g>
  </g>
  <g
     inkscape:groupmode="layer"
     id="layer2"
     inkscape:label="Layer 2">
    <g
       id="g1259"
       transform="matrix(0.23515009,0.10237153,-0.10237153,0.23515009,119.14343,213.3823)">
      <g
         inkscape:label="alien_head"
         id="layer1-5">
        <path
           style="fill:#99ff55;stroke-width:0.264583"
           id="path487"
           sodipodi:type="arc"
           sodipodi:cx="107"
           sodipodi:cy="-86"
           sodipodi:rx="75"
           sodipodi:ry="75"
           sodipodi:start="0"
           sodipodi:end="3.1360678"
           sodipodi:open="true"
           sodipodi:arc-type="arc"
           d="M 182,-86 A 75,75 0 0 1 107.20718,-11.000286 75,75 0 0 1 32.001145,-85.585638"
           transform="scale(1,-1)" />
        <path
           style="fill:#99ff55;stroke-width:0.238158"
           d="M 102.54355,182.98622 C 85.833981,174.46674 73.11188,164.54243 62.133653,151.46309 48.241292,134.91187 38.184027,113.09662 32.46332,87.10506 L 32,85 l 3.129018,0.1341 c 1.72096,0.0738 35.445888,0.19881 74.944282,0.2779 39.4984,0.0791 71.86044,0.18846 71.91566,0.24302 0.15453,0.15274 -1.34247,6.65275 -2.60581,11.31448 -3.01641,11.13049 -6.25188,19.84329 -10.93588,29.44929 -2.84287,5.83018 -4.92837,9.49001 -8.42681,14.78806 -4.80727,7.28017 -8.94336,12.33262 -15.33324,18.73033 -7.5997,7.60901 -15.47455,13.65105 -25.29359,19.40669 -3.4037,1.99515 -11.34532,6.1079 -11.79421,6.1079 -0.12105,0 -2.39619,-1.1095 -5.05587,-2.46555 z"
           id="path1038" />
      </g>
      <g
         id="layer2-4"
         inkscape:label="alie_eyes">
        <path
           style="fill:#000000;stroke:#000000;stroke-width:0.386812;stroke-opacity:1"
           d="m 86.623394,140.73829 c -10.022183,0.48544 -19.380048,-3.71385 -25.936337,-11.63878 -3.180551,-3.84449 -4.370661,-6.27303 -5.780592,-11.79587 -1.406541,-5.50957 -1.529241,-8.22593 -0.590594,-13.07451 0.550731,-2.84478 0.649065,-3.02114 1.978021,-3.54739 3.675475,-1.455464 11.283456,-1.807492 16.051786,-0.742727 6.51949,1.455797 13.286202,5.671247 17.457157,10.875257 2.96922,3.70463 4.58847,6.99923 5.829742,11.86141 1.249218,4.89331 1.39536,8.43354 0.550645,13.33925 l -0.520159,3.02087 -3.238214,0.78922 c -1.781029,0.43404 -4.391667,0.84506 -5.801435,0.91335 z"
           id="path3158" />
        <path
           style="fill:#000000;stroke:#000000;stroke-width:0.386812;stroke-opacity:1"
           d="m 126.15824,140.73826 c 10.02218,0.48544 19.38005,-3.71385 25.93634,-11.63878 3.18055,-3.84449 4.37066,-6.27303 5.78059,-11.79587 1.40654,-5.50957 1.52924,-8.22593 0.59059,-13.07451 -0.55073,-2.84479 -0.64906,-3.02114 -1.97802,-3.5474 -3.67547,-1.455462 -11.28346,-1.80749 -16.05179,-0.742725 -6.51949,1.455795 -13.2862,5.671255 -17.45715,10.875265 -2.96922,3.70463 -4.58847,6.99923 -5.82974,11.86141 -1.24922,4.89331 -1.39536,8.43354 -0.55065,13.33925 l 0.52016,3.02087 3.23821,0.78922 c 1.78103,0.43404 4.39167,0.84506 5.80144,0.91335 z"
           id="path3158-7" />
      </g>
    </g>
    <g
       id="g1326"
       transform="matrix(0.41310326,-0.19104513,0.19104513,0.41310326,19.354047,194.83908)">
      <g
         inkscape:label="frame"
         id="layer1-3">
        <ellipse
           style="fill:#e9c6af;stroke:none;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none"
           id="path846"
           cx="105.94324"
           cy="114.02549"
           rx="42.94149"
           ry="50.930481" />
      </g>
      <g
         id="layer4"
         inkscape:label="beard">
        <path
           style="fill:#999999;stroke:none;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
           d="m 84.78461,130.75634 c 13.809246,-4.63047 28.28731,-4.80213 43.51145,0 4.43748,0.0748 4.02709,4.99757 4.95488,8.58211 -16.51896,-2.50398 -34.438296,-3.14079 -55.190829,0 0.726978,-3.06264 -0.645356,-6.40519 6.724499,-8.58211 z"
           id="path3316"
           sodipodi:nodetypes="ccccc" />
        <path
           style="fill:#999999;stroke:none;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
           d="m 101.15442,165.54101 c -6.617329,-1.03099 -12.559495,-3.57636 -18.133979,-7.76785 -9.15713,-6.8853 -15.910907,-17.76778 -18.771754,-30.24725 -0.341719,-1.49064 -0.599721,-3.58053 -0.913657,-7.40093 -0.240609,-2.92805 -0.412999,-5.34819 -0.383091,-5.37809 0.0892,-0.0892 1.807301,2.48339 2.502919,3.7477 0.827526,1.50406 1.653162,3.51869 2.923958,7.13474 1.296433,3.689 3.006439,7.14371 4.559698,9.21196 5.148332,6.85522 14.887965,12.88751 31.625526,19.58739 l 0.58714,0.23502 -2.7e-4,1.64888 c -1.9e-4,0.90689 -0.0538,3.41677 -0.1191,5.57751 l -0.11866,3.92862 -1.10035,-0.0175 c -0.60519,-0.01 -1.80139,-0.12671 -2.65821,-0.2602 z"
           id="path6414" />
        <path
           style="fill:#999999;stroke:none;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
           d="m 104.80305,164.39923 c 0,-0.878 -0.0533,-3.42334 -0.11853,-5.65631 l -0.11851,-4.05995 1.88487,-0.69844 c 3.91107,-1.44926 9.81358,-3.97691 13.6605,-5.8499 7.97905,-3.88484 12.67144,-6.97292 16.54922,-10.89111 3.17772,-3.21083 5.10242,-6.4414 7.18939,-12.06729 1.69238,-4.56216 3.31393,-7.68683 5.15521,-9.93392 l 0.37,-0.45154 -0.10397,1.89648 c -0.37985,6.92885 -1.79345,13.55697 -4.1255,19.34375 -3.7597,9.32928 -9.66543,17.1256 -16.88197,22.28631 -1.79636,1.28462 -9.79215,5.29865 -11.83775,5.94275 -2.90267,0.91398 -8.1598,1.73553 -11.10579,1.73553 h -0.51717 z"
           id="path6453" />
        <path
           style="fill:#999999;stroke:none;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
           d="m 64.647191,99.584631 c 0,-1.108043 3.075011,-9.324252 4.559974,-12.183919 4.933178,-9.500071 12.059846,-16.649658 20.693301,-20.759849 2.109269,-1.004173 6.585778,-2.585582 7.052926,-2.491573 0.17965,0.03615 -5.356648,6.204625 -15.765909,17.566174 C 62.841005,101.74036 64.647191,99.789067 64.647191,99.584631 Z"
           id="path8006" />
        <path
           style="fill:#999999;stroke:none;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
           d="m 147.37077,99.717368 c 0,-1.108043 -3.07501,-9.324253 -4.55998,-12.183919 -4.93317,-9.500071 -12.05984,-16.649658 -20.6933,-20.759849 -2.10927,-1.004173 -6.58577,-2.585582 -7.05292,-2.491573 -0.17965,0.03615 5.35665,6.204625 15.76591,17.566174 18.34647,20.024899 16.54029,18.073603 16.54029,17.869167 z"
           id="path8006-8" />
        <path
           style="fill:none;stroke:#000000;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
           d="m 94.804089,139.02241 c 6.426401,4.38225 12.852791,0.26803 19.279191,0.35375"
           id="path8260"
           sodipodi:nodetypes="cc" />
        <path
           style="fill:none;stroke:#000000;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
           d="m 83.9116,95.29393 c 13.114709,-5.473219 27.17625,-0.531291 40.76463,-0.794115"
           id="path8352"
           sodipodi:nodetypes="cc" />
        <path
           style="fill:none;stroke:#000000;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
           d="m 84.266778,91.180006 c 13.11471,-5.473217 27.176252,-0.531289 40.764632,-0.794113"
           id="path8352-9"
           sodipodi:nodetypes="cc" />
      </g>
      <g
         id="layer3"
         inkscape:label="eyes">
        <g
           id="g2672"
           transform="translate(8.6334685,12.035101)">
          <path
             style="fill:none;stroke:#000000;stroke-width:0.162157px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
             d="m 68.540034,102.91656 c 7.569202,-5.537978 13.952023,-4.419034 19.74671,0"
             id="path1565"
             inkscape:transform-center-x="-0.14827107"
             inkscape:transform-center-y="2.5206052"
             sodipodi:nodetypes="cc" />
          <path
             style="fill:none;stroke:#000000;stroke-width:0.162157px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
             d="m 68.540036,102.91656 c 7.569202,5.53796 13.952023,4.41902 19.746708,0"
             id="path1565-4"
             inkscape:transform-center-x="-0.14827084"
             inkscape:transform-center-y="-2.5205997"
             sodipodi:nodetypes="cc" />
          <path
             style="fill:#ffffff;stroke:#000000;stroke-width:0.111203;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
             d="m 77.445967,106.52231 c -2.411364,-0.25088 -4.602289,-1.01955 -6.987259,-2.45141 -0.513779,-0.30846 -1.656121,-1.0712 -1.692511,-1.13009 -0.02989,-0.0483 0.02722,-0.0919 0.784354,-0.59791 3.82787,-2.55839 7.520885,-3.50762 11.188903,-2.875931 1.914113,0.329639 3.97576,1.177451 5.91783,2.433601 0.496881,0.32139 1.443512,0.9858 1.442968,1.01279 -0.0012,0.0678 -1.534887,1.09983 -2.239923,1.50746 -1.897568,1.09712 -3.633141,1.7255 -5.578685,2.01983 -0.398868,0.0604 -0.722314,0.0779 -1.593911,0.0864 -0.601422,0.006 -1.160218,0.003 -1.241766,-0.004 z"
             id="path2050" />
          <path
             style="fill:none;stroke:#000000;stroke-width:0.162157px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
             d="m 75.740853,99.521657 c -1.111704,1.823833 -1.742756,3.686303 0,6.672193"
             id="path2122"
             sodipodi:nodetypes="cc" />
          <path
             style="fill:none;stroke:#000000;stroke-width:0.162157px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
             d="m 81.643522,99.596457 c 1.111703,1.823843 1.742755,3.686303 0,6.672183"
             id="path2122-1"
             sodipodi:nodetypes="cc" />
          <circle
             style="fill:#000000;stroke:#000000;stroke-width:0.48647;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
             id="path2390"
             cx="78.761879"
             cy="102.87628"
             r="0.98229343" />
          <path
             style="fill:#28170b;stroke:#000000;stroke-width:0.111203;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
             d="m 77.37183,106.43571 c -0.279412,-0.0251 -1.515087,-0.21647 -1.521927,-0.23579 -0.0011,-0.003 -0.06474,-0.12284 -0.141237,-0.26556 -1.206147,-2.25017 -1.256136,-3.85259 -0.181793,-5.82728 l 0.24617,-0.452471 0.187777,-0.0406 c 1.623793,-0.351095 3.723282,-0.335876 5.318128,0.03855 l 0.323464,0.07594 0.133047,0.230197 c 0.4245,0.734444 0.721818,1.509224 0.828008,2.157714 0.07322,0.44718 0.05388,1.29435 -0.03934,1.72365 -0.162115,0.74643 -0.768304,2.18222 -0.954611,2.26104 -0.1352,0.0572 -0.948291,0.21591 -1.403301,0.27392 -0.500027,0.0637 -2.319095,0.10327 -2.794377,0.0607 z m 1.666322,-2.35569 c 0.458824,-0.0744 0.862576,-0.50088 0.939681,-0.99257 0.109398,-0.69764 -0.331296,-1.32151 -1.008505,-1.42771 -0.859986,-0.13486 -1.558578,0.56583 -1.423422,1.42771 0.05991,0.38204 0.307634,0.72112 0.657052,0.89939 0.154,0.0786 0.484968,0.14755 0.614511,0.12809 0.04077,-0.006 0.140083,-0.0218 0.220683,-0.0348 z"
             id="path2546" />
        </g>
        <g
           id="g2672-0"
           transform="translate(45.846245,12.035101)">
          <path
             style="fill:none;stroke:#000000;stroke-width:0.162157px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
             d="m 68.540034,102.91656 c 7.569202,-5.537978 13.952023,-4.419034 19.74671,0"
             id="path1565-5"
             inkscape:transform-center-x="-0.14827107"
             inkscape:transform-center-y="2.5206052"
             sodipodi:nodetypes="cc" />
          <path
             style="fill:none;stroke:#000000;stroke-width:0.162157px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
             d="m 68.540036,102.91656 c 7.569202,5.53796 13.952023,4.41902 19.746708,0"
             id="path1565-4-6"
             inkscape:transform-center-x="-0.14827084"
             inkscape:transform-center-y="-2.5205997"
             sodipodi:nodetypes="cc" />
          <path
             style="fill:#ffffff;stroke:#000000;stroke-width:0.111203;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
             d="m 77.445967,106.52231 c -2.411364,-0.25088 -4.602289,-1.01955 -6.987259,-2.45141 -0.513779,-0.30846 -1.656121,-1.0712 -1.692511,-1.13009 -0.02989,-0.0483 0.02722,-0.0919 0.784354,-0.59791 3.82787,-2.55839 7.520885,-3.50762 11.188903,-2.875931 1.914113,0.329639 3.97576,1.177451 5.91783,2.433601 0.496881,0.32139 1.443512,0.9858 1.442968,1.01279 -0.0012,0.0678 -1.534887,1.09983 -2.239923,1.50746 -1.897568,1.09712 -3.633141,1.7255 -5.578685,2.01983 -0.398868,0.0604 -0.722314,0.0779 -1.593911,0.0864 -0.601422,0.006 -1.160218,0.003 -1.241766,-0.004 z"
             id="path2050-3" />
          <path
             style="fill:none;stroke:#000000;stroke-width:0.162157px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
             d="m 75.740853,99.521657 c -1.111704,1.823833 -1.742756,3.686303 0,6.672193"
             id="path2122-6"
             sodipodi:nodetypes="cc" />
          <path
             style="fill:none;stroke:#000000;stroke-width:0.162157px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
             d="m 81.643522,99.596457 c 1.111703,1.823843 1.742755,3.686303 0,6.672183"
             id="path2122-1-3"
             sodipodi:nodetypes="cc" />
          <circle
             style="fill:#000000;stroke:#000000;stroke-width:0.48647;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
             id="path2390-0"
             cx="78.761879"
             cy="102.87628"
             r="0.98229343" />
          <path
             style="fill:#28170b;stroke:#000000;stroke-width:0.111203;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
             d="m 77.37183,106.43571 c -0.279412,-0.0251 -1.515087,-0.21647 -1.521927,-0.23579 -0.0011,-0.003 -0.06474,-0.12284 -0.141237,-0.26556 -1.206147,-2.25017 -1.256136,-3.85259 -0.181793,-5.82728 l 0.24617,-0.452471 0.187777,-0.0406 c 1.623793,-0.351095 3.723282,-0.335876 5.318128,0.03855 l 0.323464,0.07594 0.133047,0.230197 c 0.4245,0.734444 0.721818,1.509224 0.828008,2.157714 0.07322,0.44718 0.05388,1.29435 -0.03934,1.72365 -0.162115,0.74643 -0.768304,2.18222 -0.954611,2.26104 -0.1352,0.0572 -0.948291,0.21591 -1.403301,0.27392 -0.500027,0.0637 -2.319095,0.10327 -2.794377,0.0607 z m 1.666322,-2.35569 c 0.458824,-0.0744 0.862576,-0.50088 0.939681,-0.99257 0.109398,-0.69764 -0.331296,-1.32151 -1.008505,-1.42771 -0.859986,-0.13486 -1.558578,0.56583 -1.423422,1.42771 0.05991,0.38204 0.307634,0.72112 0.657052,0.89939 0.154,0.0786 0.484968,0.14755 0.614511,0.12809 0.04077,-0.006 0.140083,-0.0218 0.220683,-0.0348 z"
             id="path2546-8" />
        </g>
        <ellipse
           style="fill:none;stroke:#000000;stroke-width:0.79375;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
           id="path2832"
           cx="87.413872"
           cy="115.47264"
           rx="12.487696"
           ry="12.179357" />
        <ellipse
           style="fill:none;stroke:#000000;stroke-width:0.79375;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
           id="path2832-5"
           cx="124.26028"
           cy="115.62682"
           rx="12.487696"
           ry="12.179357" />
        <path
           style="fill:none;stroke:#000000;stroke-width:0.79375;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
           d="m 100.04799,115.57992 c 3.37132,-1.21476 5.29841,-5.63892 11.75389,0"
           id="path2969"
           sodipodi:nodetypes="cc" />
      </g>
    </g>
    <ellipse
       style="fill:#ffe680;fill-opacity:0.469636;stroke:none;stroke-width:0.529167"
       id="path1372"
       cx="83.647423"
       cy="223.0598"
       rx="27.056328"
       ry="26.023643" />
    <ellipse
       style="fill:#ffe680;fill-opacity:0.469636;stroke:none;stroke-width:0.529167"
       id="path1372-7"
       cx="135.90126"
       cy="247.43115"
       rx="27.056328"
       ry="26.023643" />
  </g>
</svg>
