<script>
	import Placeholder from "./Placeholder.svelte"
</script>

<main>
	<Placeholder />
</main>

<style>
	main {
		height: 100vh;
		width: 100vw;
		background: #111111;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	main :global(svg)	 {
		height: 95vh;
	}
</style>
